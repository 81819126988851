<template>
  <div class="manage-consult">
    <div class="banner" v-if="banners != ''">
      <!-- <el-carousel :interval="4000"> -->
      <!-- <el-carousel-item v-for="(item, i) in banners" :key="i"> -->
      <img @dragstart.prevent :src="banners[0].img" width="100%" height="100%"
        :style="{ cursor: banners[0].linkUrl ? 'pointer' : '' }" @click="gotoNewPage(banners[0].linkUrl)" />
      <!-- </el-carousel-item> -->
      <!-- </el-carousel> -->
    </div>
    <div class="content-box-one">
      <div class="title">
        <Title :title="'服务内容'" :enTitle="'SERVICE CONTENT'"></Title>
      </div>
      <div class="items-box">
        <div class="common-box-one">
          <div class="mask">
            <div class="mask-title">区域市场研究报告</div>
            <div class="mask-txt">
              研判全国、省、地市、区县建筑业市场发展前景，发现市场机会，为用户企业优化市场布局、调整资源配置策略提供决策依据。
            </div>
            <div>
              <button class="mask-btn" @click="goCustom(1)">我要定制</button>
            </div>
          </div>
          <div class="box-img">
            <img @dragstart.prevent src="../../../assets/img/consult/reportCustom/area-icon.png" alt="" />
          </div>
          <div class="box-title">区域市场研究报告</div>
          <div class="box-txt">
            研判全国、省、地市、区县建筑业市场发展前景，发现市场机会，为用户企业优化市场布局、调整资源配置策略提供决策依据。
          </div>
        </div>
        <div class="common-box-two">
          <div class="mask">
            <div class="mask-title">细分市场研究报告</div>
            <div class="mask-txt">
              研判建筑业细分市场发展前景，挖掘潜力细分市场，为用户企业开拓新业务市场、实施业务转型升级提供精准助力。
            </div>
            <div>
              <button class="mask-btn" @click="goCustom(2)">我要定制</button>
            </div>
          </div>
          <div class="box-img">
            <img @dragstart.prevent src="../../../assets/img/consult/reportCustom/industy-icon.png" alt="" />
          </div>
          <div class="box-title">细分市场研究报告</div>
          <div class="box-txt">
            研判建筑业细分市场发展前景，挖掘潜力细分市场，为用户企业开拓新业务市场、实施业务转型升级提供精准助力。
          </div>
        </div>
        <div class="common-box-three">
          <div class="mask">
            <div class="mask-title">客户研究报告</div>
            <div class="mask-txt">
              识别推荐新客户、跟踪大客户，深度分析客户需求、招标规则、关联关系等，为用户企业客户开发与管理提供精准支持。
            </div>
            <div>
              <button class="mask-btn" @click="goCustom(3)">我要定制</button>
            </div>
          </div>
          <div class="box-img">
            <img @dragstart.prevent src="../../../assets/img/consult/reportCustom/customer-icon.png" alt="" />
          </div>
          <div class="box-title">客户研究报告</div>
          <div class="box-txt">
            识别推荐新客户、跟踪大客户，深度分析客户需求、招标规则、关联关系等，为用户企业客户开发与管理提供精准支持。
          </div>
        </div>
        <div class="common-box-four">
          <div class="mask">
            <div class="mask-title">竞争研究报告</div>
            <div class="mask-txt">
              针对某一区域、领域或重点客户，分析其市场集中度、开放度、市场竞争态势等，为用户企业参与市场竞争提供建设性建议。
            </div>
            <div>
              <button class="mask-btn" @click="goCustom(4)">我要定制</button>
            </div>
          </div>
          <div class="box-img">
            <img @dragstart.prevent src="../../../assets/img/consult/reportCustom/army-icon.png" alt="" />
          </div>
          <div class="box-title">竞争研究报告</div>
          <div class="box-txt">
            针对某一区域、领域或重点客户，分析其市场集中度、开放度、市场竞争态势等，为用户企业参与市场竞争提供建设性建议。
          </div>
        </div>
      </div>
    </div>
    <div class="content-box-three">
      <div class="title">
        <Title :title="'服务流程'" :enTitle="'SERVICE PROCESS'"></Title>
      </div>
      <div class="items-box">
        <div class="img-box">
          <img src="../../../assets/img/consult/manageConsult/communicate.png" alt="" width="100%" height="100%" />
          <span class="item-txt">前期沟通</span>
        </div>
        <div class="line">
          <img src="../../../assets/img/consult/manageConsult/progressline.png" alt="" width="100%" height="100%" />
        </div>
        <div class="img-box">
          <img src="../../../assets/img/consult/manageConsult/confirm.png" alt="" width="100%" height="100%" />
          <span class="item-txt">确认需求</span>
        </div>
        <div class="line">
          <img src="../../../assets/img/consult/manageConsult/progressline.png" alt="" width="100%" height="100%" />
        </div>
        <div class="img-box">
          <img src="../../../assets/img/consult/manageConsult/amount.png" alt="" width="100%" height="100%" />
          <span class="item-txt">报价磋商</span>
        </div>
        <div class="line">
          <img src="../../../assets/img/consult/manageConsult/progressline.png" alt="" width="100%" height="100%" />
        </div>
        <div class="img-box">
          <img src="../../../assets/img/consult/manageConsult/aggree.png" alt="" width="100%" height="100%" />
          <span class="item-txt">签订协议</span>
        </div>
        <div class="line">
          <img src="../../../assets/img/consult/manageConsult/progressline.png" alt="" width="100%" height="100%" />
        </div>
        <div class="img-box">
          <img src="../../../assets/img/consult/manageConsult/project.png" alt="" width="100%" height="100%" />
          <span class="item-txt">项目实施</span>
        </div>
        <div class="line">
          <img src="../../../assets/img/consult/manageConsult/progressline.png" alt="" width="100%" height="100%" />
        </div>
        <div class="img-box">
          <img src="../../../assets/img/consult/manageConsult/question.png" alt="" width="100%" height="100%" />
          <span class="item-txt">过程沟通</span>
        </div>
        <div class="line">
          <img src="../../../assets/img/consult/manageConsult/progressline.png" alt="" width="100%" height="100%" />
        </div>
        <div class="img-box">
          <img src="../../../assets/img/consult/manageConsult/result.png" alt="" width="100%" height="100%" />
          <span class="item-txt">交付成果</span>
        </div>
        <div class="line">
          <img src="../../../assets/img/consult/manageConsult/progressline.png" alt="" width="100%" height="100%" />
        </div>
        <div class="img-box">
          <img src="../../../assets/img/consult/manageConsult/service.png" alt="" width="100%" height="100%" />
          <span class="item-txt">跟踪服务</span>
        </div>
      </div>
    </div>
    <div class="content-box-four" id="dataForm">
      <div class="title">
        <Title :title="'提交需求'" :enTitle="'SUBMIT REQUIREMENTS'" style="margin-top: 20px"></Title>
      </div>
      <div class="items-box">
        <div class="box-left">
          <div class="left-title">立即定制，不一样的需求</div>
          <div class="left-txt">联系建筑企业经营决策支持平台</div>
          <div class="left-txt2">业务咨询 {{ servicePhone }}</div>
          <div class="left-txt3"></div>
        </div>
        <div class="box-right">
          <el-form :model="dataPO" :rules="rules" ref="dataPO" label-width="100px" class="demo-ruleForm">
            <el-row>
              <el-col :span="12">
                <el-form-item label="您的姓名" prop="contactName" class="title-item">
                  <el-input v-model.trim="dataPO.contactName" maxlength="200" placeholder="请输入您的姓名"></el-input>
                </el-form-item></el-col>
              <el-col :span="12">
                <el-form-item label="您的联系方式" prop="contactNumber">
                  <el-input v-model.trim="dataPO.contactNumber" placeholder="请输入联系座机号或手机号"></el-input>
                </el-form-item></el-col>
              <el-col :span="12">
                <el-form-item label="您的单位" prop="companyName" class="title-item">
                  <el-input v-model.trim="dataPO.companyName" maxlength="200" placeholder="请输入您的单位"></el-input>
                </el-form-item></el-col>
              <el-col :span="12">
                <el-form-item label="您的职务" prop="contactPost">
                  <el-input v-model.trim="dataPO.contactPost" maxlength="200" placeholder="请输入您的职务"></el-input>
                </el-form-item></el-col>
              <el-col :span="12">
                <el-form-item label="您的行业" prop="sector" class="title-item">
                  <el-input v-model.trim="dataPO.sector" maxlength="200" placeholder="请输入您的行业"></el-input>
                </el-form-item></el-col>
              <el-col :span="12">
                <el-form-item label="您期望的交付时间" prop="expectedDeliveryDate">
                  <el-date-picker type="date" placeholder="请选择时间" v-model="dataPO.expectedDeliveryDate"
                    :picker-options="pickerOptions" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
                </el-form-item></el-col>
              <el-col :span="12">
                <el-form-item label="报告类型" prop="researchReportTypeCode" class="title-item"><el-select
                    v-model="dataPO.researchReportTypeCode" placeholder="请选择研究报告类型">
                    <el-option v-for="(item, index) in reportTypeList" :label="item.dictLabel" :value="item.dictValue"
                      :key="index"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="需求描述" prop="detail" class="desc-item title-item">
                  <el-input type="textarea" v-model.trim="dataPO.detail" :rows="6" maxlength="1000" resize="none"
                    placeholder="限1000字以内"></el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item class="title-item">
                  <el-button type="primary" @click="submitForm">立即定制</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <div class="content-box-two" style="background: #ffffff">
      <div class="title">
        <Title :title="'常见问题'" :enTitle="'COMMON PROBLEMS'"></Title>
      </div>
      <div class="items-box">
        <div class="common-box">
          <div class="box-top">
            <div class="top1">
              <img @dragstart.prevent src="../../../assets/img/consult/reportCustom/q-icon.png" alt="" />
            </div>
            <div class="top2">定制报告都有哪些内容？</div>
          </div>
          <div class="box-bottom">
            <div class="bottom1">
              <img @dragstart.prevent src="../../../assets/img/consult/reportCustom/a-icon.png" alt="" />
            </div>
            <div class="bottom2">
              定制报告分为区域市场研究报告、细分市场研究报告、客户研究报告、竞争研究报告四类，请点击进入详情页查看报告样例。
            </div>
          </div>
        </div>
        <div class="common-box">
          <div class="box-top">
            <div class="top1">
              <img @dragstart.prevent src="../../../assets/img/consult/reportCustom/q-icon.png" alt="" />
            </div>
            <div class="top2">定制报告与产品化的研究报告有什么区别？</div>
          </div>
          <div class="box-bottom">
            <div class="bottom1">
              <img @dragstart.prevent src="../../../assets/img/consult/reportCustom/a-icon.png" alt="" />
            </div>
            <div class="bottom2">
              定制报告是为用户企业量身定制的，根据用户的实际情况进行针对性分析，提出针对性建议，而产品化的研究报告是通用型的报告。
            </div>
          </div>
        </div>
        <div class="common-box">
          <div class="box-top">
            <div class="top1">
              <img @dragstart.prevent src="../../../assets/img/consult/reportCustom/q-icon.png" alt="" />
            </div>
            <div class="top2">如何获取定制报告？</div>
          </div>
          <div class="box-bottom">
            <div class="bottom1">
              <img @dragstart.prevent src="../../../assets/img/consult/reportCustom/a-icon.png" alt="" />
            </div>
            <div class="bottom2">
              请点击“我要定制”，提交您的需求，咨询服务人员在与您联系确认后，请通过平台在线支付定制费用。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title";
import { mapGetters } from "vuex"
export default {
  components: {
    Title,
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      const moblie =
        "^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-9])|(18[0-9])|166|198|199|191|195|193|(147))\\d{8}$";
      const tel = /^(\d{3,4}-)?\d{7,8}$/;
      if (!new RegExp(moblie).test(value) && !tel.test(value) && value !== "") {
        callback(new Error("请输入格式正确的联系方式"));
      } else {
        callback();
      }
    };
    return {
      banners: [
        {
          linkUrl: "",
          img: "",
        },
      ],
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      reportTypeList: [],
      dataPO: {
        contactName: "",
        contactNumber: "",
        companyName: "",
        contactPost: "",
        sector: "",
        expectedDeliveryDate: "",
        researchReportTypeCode: "",
        detail: "",
        terminal: "60441001",
      },
      rules: {
        contactName: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        companyName: [{ required: true, message: "请输入您的单位", trigger: "blur" }],
        contactNumber: [
          {
            required: true,
            message: "请输入联系座机号或手机号",
            trigger: "blur",
          },
          {
            validator: validatePhone,
          },
        ],
        expectedDeliveryDate: [
          { required: true, message: "请选择您期望的交付时间", trigger: "blur" },
        ],
        researchReportTypeCode: [
          { required: true, message: "请选择研究报告类型", trigger: "change" },
        ],
        detail: [{ required: true, message: "请输入您的需求描述", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapGetters(["servicePhone"]),
  }, 
  mounted() {
    // 数据埋点
    this.$api.home.buriedPoint({
      terminal: "60441001",
      consumerId: this.$session.getUsers() ? this.$session.getUsers().consumerId : "",
      positionCode: "604410011007",
    });
    this.getBannerList();
    this.reportCustomType();
  },
  methods: { 
    // 获取轮播
    getBannerList() {
      this.$api.home
        .getBannerList({
          code: "10051001",
          platform: 1,
        })
        .then((res) => {
          this.banners = res.data;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 获取报告定制类型
    reportCustomType() {
      this.$api.consult
        .reportCustomType({
          type: "6052",
        })
        .then((res) => {
          this.reportTypeList = res.data;
        })
        .catch((msg) => {
          if (msg?.msg) {
            this.$message.error(msg?.msg);
          }
        });
    },
    // 立即定制滚动事件
    scrollToCustom() {
      let anchor = document.querySelector("#dataForm");
      window.scrollTo({
        top: anchor.offsetTop,
        behavior: "smooth",
      });
    },
    // banner点击跳转
    gotoNewPage(url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        let anchor = document.querySelector("#dataForm");
        window.scrollTo({
          top: anchor.offsetTop,
          behavior: "smooth",
        });
      }
    },
    // 我要定制按钮点击
    goCustom(type) {
      this.$router.push(`/consult/report-custom-details?type=${type}`);
    },
    // 表单提交事件
    submitForm() {
      this.$refs["dataPO"].validate((valid) => {
        if (valid) {
          this.$api.consult
            .reportCustom(this.dataPO)
            .then(() => {
              this.$message.success("提交成功");
              this.$refs.dataPO.resetFields();
            })
            .catch((msg) => {
              if (msg?.msg) {
                this.$message.error(msg?.msg);
              }
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
